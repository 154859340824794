<template>
  <div>
    <BannersHighlight
      v-if="showCanadianOwnerBanner"
      cookie-name="homepage"
      variant="primary-light"
      alignment="center"
    />
    <LandingPageBanner :image="heroImage">
      <h1>{{ t('homepageSearch.title') }}</h1>
      <p class="mb-4">
        {{ isMobile ? t('homepageSearch.mobileSubtitle') : t('homepageSearch.subtitle') }}
      </p>
      <!-- TODO Search -->
      <LandingPageSearchForm />
    </LandingPageBanner>

    <ContentBuyAnRvCtaBanner v-if="isInCaOrUs" />

    <div class="bg-primary-50 py-5">
      <ZContainer>
        <ContentBenefits />
      </ZContainer>
    </div>

    <ZContainer class="py-5">
      <ContentPopularRvs />
    </ZContainer>

    <ZContainer class="py-5">
      <ContentTopDestinations />
    </ZContainer>

    <ZContainer class="py-5">
      <h2>{{ t('realPeopleSaying') }}</h2>
      <ContentPopularReviews />
    </ZContainer>

    <ZContainer class="py-5">
      <ContentPopularBlogSections />
    </ZContainer>

    <NuxtPage />
  </div>
</template>

<script setup lang="ts">
import { IMAGE_HOMEPAGE_MAP } from '~/constants/image'

const { t } = useI18n()

const { isMobile } = useDevice()
const runtimeConfig = useRuntimeConfig()
const { showCanadianOwnerBanner } = useExperiment()
const heroImage = computed(() => {
  const suffix = isMobile ? 'mobile' : 'desktop'
  const image = IMAGE_HOMEPAGE_MAP[suffix][showCanadianOwnerBanner.value ? 'canadian-owner' : 'default']

  return `${runtimeConfig.public.staticAssetsURL}/web/images/${image}`
})

const { isInCaOrUs } = useGeolocation()

useHead({
  link: [
    {
      rel: 'preload',
      as: 'image',
      type: 'image/webp',
      href: heroImage,
    },
  ],
})

const aggregateReview = await useAggregateReviews()
useJsonld(() => ({
  '@context': 'https://schema.org',
  '@graph': [
    {
      '@type': 'WebSite',
      'url': 'https://www.rvezy.com',
      'potentialAction': {
        '@type': 'SearchAction',
        'target': {
          '@type': 'EntryPoint',
          'urlTemplate': 'https://www.rvezy.com/rv-search?{search_query}',
        },
        'query-input': {
          '@type': 'PropertyValueSpecification',
          'valueRequired': 'https://schema.org/False',
          'valueName': 'search_query',
        },
      },
    },
    getReviewSnippet({ aggregateReview: aggregateReview.value }),
  ],
}))
</script>

<i18n lang="json">
{
  "en": {
    "realPeopleSaying": "Here is what real people are saying about RVezy",
    "homepageSearch": {
      "title": "Find your perfect RV rental",
      "subtitle": "Explore the best travel trailers, motorhomes, and camper vans for your RV adventure.",
      "mobileSubtitle": "Explore motorhome and trailer rentals near you. Drive it, tow it, or get it delivered."
    }
  },
  "fr": {
    "realPeopleSaying": "Voici ce que les gens disent de RVezy",
    "homepageSearch": {
      "title": "Trouvez votre VR idéal",
      "subtitle": "Découvrez les meilleures roulottes et motorisés pour votre aventure en VR.",
      "mobileSubtitle": "Réservez un VR pour votre voyage. Explorez les meilleurs motorisés et roulottes près de chez vous."
    }
  }
}
</i18n>
