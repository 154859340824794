export default function useMarketplaceUrl() {
  const runtimeConfig = useRuntimeConfig()
  const { locale } = useI18n()

  const baseUrl = computed(() => `${runtimeConfig.public.apps.marketplace.baseURL}${locale.value === 'fr' ? '/fr' : '/en'}`)

  function getMarketplaceUrl() {
    return `${baseUrl.value}`
  }
  return {
    getMarketplaceUrl,
  }
}
