<template>
  <div class="landing-page-banner">
    <div
      v-if="showCanadianOwnerBanner"
      class="overlay"
    />
    <img
      :src="image"
      alt=""
      :width="imageDimensions.width"
      :height="imageDimensions.height"
      class="landing-page-banner--image"
      decoding="async"
    >
    <ZContainer class="landing-page-banner--content">
      <slot />
    </ZContainer>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  image: string
}>()

const { isMobile } = useDevice()
const imageDimensions = computed(() => {
  return isMobile
    ? {
        width: 750,
        height: 1211,
      }
    : {
        width: 1600,
        height: 750,
      }
})

const { showCanadianOwnerBanner } = useExperiment()
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%);
}

.landing-page-banner--content {
  position: relative;
  z-index: 2;
}
</style>
